<template>
  <div class="fade-in">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          id="modalTitle"
          class="modal-header">
          <h3 width="100%" class="text-center">
            Admin's Reply
          </h3>
          <button
            type="button"
            class="btn-close"
            aria-label="Close modal"
            @click="$emit('close')"
          >
            &nbsp;&nbsp;&nbsp;x
          </button>
        </header>

        <section class="modal-body">
          {{commentinfo.guestname}}, {{commentinfo.guestcreated_at}}<br>
          {{commentinfo.guestcomment}}
          <hr>
              write reply
                <input
                  v-model="message"
                  type="text"
                  placeholder="Enter reply"
                >
        </section>
        <footer class="modal-footer">
          <button type="button" class="btn-blue" @click="myformaction">
            Save Comments
          </button>
          <button type="button" class="btn-blue" @click="deletecomment">
            Delete Visitor Comments
          </button>
          <button type="button" class="btn-green" @click="$emit('close')" aria-label="Close modal">
            Close me!
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: [ 'commentinfo'],
    data() {
      return {
      author : "",
      message : "",
      error: ""
      }
    },
   methods: {
    deletecomment() {
      //console.log(this.message)
      //console.log(this.author)
      fetch('./api/comments.php',
      {
        mode: 'cors',
        method: "POST",
        body: JSON.stringify({action: "deletecomment", id: this.commentinfo.commentid})
      })
      .then((response)=> {
        if (response.ok) {
          this.$emit('admin_reply_saved')
          this.$emit('close')
          return response.json();
        }
      })
      .then((data) => {
        //console.log(data);
      })
    },
    myformaction() {
      //console.log(this.message)
      //console.log(this.author)
      fetch('./api/comments.php',
      {
        mode: 'cors',
        method: "POST",
        body: JSON.stringify({action:"writecomments", parent_id: this.commentinfo.commentid, message: this.message, author: 'admin'})
      })
      .then((response)=> {
        if (response.ok) {
          this.$emit('admin_reply_saved')
          this.$emit('close')
          return response.json();
        }
      })
      .then((data) => {
        //console.log(data);
      })
    },
    }
}
</script>

<style scoped>
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    background: white;
    text-align: center;
    animation-name: example;
    animation-duration: 2s;
    border-radius: 22px;
    border: none;

    /*animation-iteration-count: 3;    */
  }

  @keyframes example {
    from {background-color: red;}
    to {background-color: white;}
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header,
  .modal-footer {
    padding: 5px;
    justify-content: center;
    background-color: rgb(136, 85, 9);
    border-radius: 5px;
    display: flex;
  }

  .modal-header {
    position: relative;
    /*border-bottom: 1px solid #eeeeee;*/
    border-bottom: 1px solid black;
    color: white;
    justify-content: space-between;
    border-radius: 5px;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
  }

  .modal-body {
    position: relative;
    padding: 10px 10px;
    justify-content: left;
    border-radius: 5px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    /*background: #4AAE9B;*/
    background: rgb(136, 85, 9);
    border: 1px solid #4AAE9B;
    border-radius: 10px;
  }

  .btn-blue {
    color: white;
    /*background: #4AAE9B;*/
    background:blue;
    border: 1px solid #4AAE9B;
    border-radius: 5pxpx;
    font-size: 16px;
    margin: 3px;
  }

  .fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.grid-container {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0px;
  background-color: white;
  padding: 1px;
}

.inputFieldTop {
  grid-column-start: 1;
  grid-column-end: 2;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: -5px;
}

/*  bottom */
.inputFieldBot {
  grid-column-start: 3;
  grid-column-end: 5;
  margin-top: -10px;
}

</style>
