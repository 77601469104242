<template>
  <v-container
    id="blog"
    tag="section"
  >
    <div class="mypagetitle">ビジターコメント</div>
        <button class="mybutton" @click="open_new_comment_form">クリックして新しいコメントを書く</button>
        <hr>
        コメントのリスト
        <div class="wrapper">
          <div class="box a"><button class="mysmallbutton" @click="prev_page">前 page</button></div>
          <div class="box b"><p>page {{currentpage}} &nbsp;/&nbsp;{{npages}}</p></div>
          <div class="box c"><button class="mysmallbutton" @click="next_page">次の page</button></div>
        </div>
<!--
        <button class="mysmallbutton" @click="prev_page">前 page</button>
        <p>page {{currentpage}} &nbsp;/&nbsp;{{npages}}</p>
        <button class="mysmallbutton" @click="next_page">次の page</button>
-->
        <hr>


              <new-comment
                v-if="open_visitor_form_flag"
                :guide="guide"
                @close="open_visitor_form_flag=false"
                @visitor_comment_saved="visitor_comment_saved"
              >
              </new-comment>

              <new-reply
                v-if="open_admin_form_flag"
                :commentinfo="commentinfo"
                @close="open_admin_form_flag=false"
                @admin_reply_saved="admin_reply_saved"
              >
              </new-reply>
        <v-row>
            <v-col cols="12">
                <v-row class="mb-12" v-for="(each,index) in mycommentlist" v-bind:key="index">
                    <v-col cols="12">
                      <div class="mycard">
                        <p style="padding: 0px; margin:1px" @click="open_admin_form(index)"><b>{{each.comment.author}}, &nbsp; {{each.comment.created_at}}</b></p>
                        <p style="padding: 0px; margin:1px" @click="open_admin_form(index)">{{each.comment.message}}</p>
                        <div v-if="each.reply.length>0">
                        <p style="padding: 0px; margin:1px"><b>{{each.reply[0].author}}, &nbsp; {{each.reply[0].created_at}}</b></p>
                        <p style="padding: 0px; margin:1px">{{each.reply[0].message}}</p>
                        </div>
                      </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import NewComment from './NewComment.vue';
import NewReply from './NewReply.vue';
import { mapGetters } from 'vuex'
export default {
    name: 'Blog',

    components: {
      NewComment, NewReply,
    },
    data() {
      return {
      open_visitor_form_flag : false,
      open_admin_form_flag : false,
      mycommentlist: [],
      currentpage: 1,
      npages: 1,
      prev_available: 1,
      next_available: 1,
      guide: {},
      chosenRating: null,
      invalidInput: false,
      error: null,
      commentinfo: {},
    };
    },
     mounted() {
    this.fetch_comments();
  },
  computed: {
      ...mapGetters( ['stCurrentLang']),
      mylanguage() {
        return this.stCurrentLang
      },
  },

   methods: {
      open_new_comment_form : function() {
        //console.log('open new comment form')
        this.commentinfo={};
        this.open_visitor_form_flag = true;
      },
      open_admin_form(myindex) {
        var pass = prompt("Please enter password");

        if (pass != 'h12341234') {
          alert('wrong password');
          return;
        }
        this.commentinfo={
          commentid: this.mycommentlist[myindex].comment.id,
          guestname: this.mycommentlist[myindex].comment.author,
          guestcomment: this.mycommentlist[myindex].comment.message,
          guestcreated_at: this.mycommentlist[myindex].comment.created_at,
        };
        this.open_admin_form_flag = true
      },
      prev_page() {
        this.currentpage--;
        if (this.currentpage<=1) this.currentpage=1;
        this.fetch_comments();
      },
      next_page() {
        this.currentpage++;
        if (this.currentpage>=this.npages) this.currentpage=this.npages;
        this.fetch_comments();
      },
      visitor_comment_saved() {
        this.fetch_comments();
      },
      admin_reply_saved() {
        this.fetch_comments();
      },
    fetch_comments() {
      this.error = null;
      fetch('./api/comments.php',
      {
        mode: 'cors',
        method: "POST",
        body: JSON.stringify({action:"readcomments",currentpage:this.currentpage,lang:this.mylanguage})
      })
      .then((response)=> {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        //console.log(data);
        this.mycommentlist = data.comments;
        this.npages = data.totalpage;
        this.prev_available = data.prev_available;
        this.next_available = data.next_available;
        this.guide = data.guide;
        //console.log('prev_available')
        //console.log(this.prev_available)
        //console.log('next_available')
        //console.log(this.next_available)
        //console.log(data.guide)
      })
      .catch((error) => {
        //console.log(error);
        this.error = 'Failed to fetch comments. Try again.'
      });
    },
   }
}
</script>

<style scoped>
.mycard {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 5px;
}

.mycard:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.mybutton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.mysmallbutton {
  background-color: #5b4caf;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.wrapper {
  display: grid;
  grid-template-columns: 31% 31% 31%;
  grid-gap: 5px;
  background-color: #fff;
  color: rgb(243, 203, 203);
}

.box {
  background-color: rgb(243, 203, 203);
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  font-size: 100%;
}

</style>
